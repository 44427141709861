import '../commande/Commande.scss'
import { Button, Form, Input, Modal, notification, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { ReactNode, useEffect, useRef, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import CLOSE_ICON from '../../../../../assets/images/close-success.png'
import ERROR from '../../../../../assets/images/error.png'
import SUCCESS from '../../../../../assets/images/success.png'
import CSwitch from '../../../../../components/Common/CSwitch/CSwitch'
import CustomPopup from '../../../../../components/Common/CustomPopup/CustomPopup'
import DisplayDocumentsCmd from '../../../../../components/Common/Documents/DisplayDocumentsCmd'
import Loading from '../../../../../components/Common/Loading/Loading'
import Define from '../../../../../constants/define'
import { StatusAPI } from '../../../../../enum'
import { CommandeScene } from '../../../../../enum/CommandeScene'
import { MODE } from '../../../../../enum/mode'
import { popupType } from '../../../../../enum/popupType'
import { StatusCommande } from '../../../../../enum/StatusCommande'
import useCommandeEdit from '../../../../../hook/commande/useCommandeEdit'
import commandeApi from '../../../../../http/commandeApi'
import { removeTones } from '../../../../../utils'
import {
  convertDataTabletoAPI,
  getNewFile,
} from '../../../../../utils/commande/convertDataTabletoAPI'
import FormDestination from '../../../components/commande/FormDestination'
import FormLivraison from '../../../components/commande/FormLivraison'
import { DataTableParam } from '../../../stockscreen/component/model'
import SectionLayout from '../../../stockscreen/component/SectionLayout'
import { ModalName } from '../../customModalSlice'
import DetailSection from './DetailSection'
import {
  fakeSearchApi,
  getLineWithSourceFromDataTable,
  redirectToCmdScreen,
  removeFakeId,
  uploadNonList,
  uploadPickingList,
} from './func'
import { DocumentStatus } from '../../../../../enum/DocumentStatus'
import { useDispatch, useSelector } from 'react-redux'
import { clearCurrentDestinataire } from '../../../../../redux/reducers/destinataireSlice'
import { clearCurrentTransporteur } from '../../../../../redux/reducers/transporteurSlice'
import { RootState } from '../../../../../app/store'
import { t } from 'i18next'
import { focusById } from '../../../../../hook/usePressTab'
import { sourceType } from '../../../../../enum/sourceType'

interface ActionProps {
  onClickPlusBtn?(): void

  isShowIconPlus?: boolean
}

const Action = ({
  isShowIconPlus = true,
  onClickPlusBtn = () => {},
}: ActionProps) => {
  const onClickPlus = (): void => {
    if (onClickPlusBtn) onClickPlusBtn()
  }

  return (
    <div className="flex items-center gap-3">
      {isShowIconPlus && (
        <i className={'icon-add-plus'} onClick={onClickPlus}></i>
      )}
    </div>
  )
}

const CommandeEdit = () => {
  const commandeKey = useSelector((state: RootState) => state.selector).data
    .commande
  const [searchParams] = useSearchParams()
  const pageIndex = Number(searchParams.get('page-index')) || 1
  const pageSize = Number(searchParams.get('page-size')) || 25
  const navigate = useNavigate()
  const params = useParams()
  const [openPopupDelete, setOpenPopupDelete] = useState<boolean>(false)
  const [openPopupImport, setOpenPopupImport] = useState<boolean>(false)
  const [errorClientCode, setErrorClientCode] = useState<string>('')
  const [clickedOnMEP, setClickOnMEP] = useState<boolean>(false)
  const [clickedOnSearch, setClickedOnSearch] = useState<boolean>(false)
  const goToEmptyTransporteur = useRef<HTMLInputElement>(null)
  const goToEmptyDestinataire = useRef<HTMLInputElement>(null)
  const [isOpenModalConfirmModifyTable, setIsOpenModalConfirmModifyTable] =
    useState<boolean>(false)
  const [isOpenModalDeleteCommande, setIsOpenModalDeleteCommande] =
    useState<boolean>(false)
  useState<boolean>(false)
  const count = Number(searchParams.get('count')) || 0
  const dispatch = useDispatch()

  const { pathname } = useLocation()
  const {
    form,
    loading,
    initLoading,
    isCreateTransporteur,
    submitted,
    isEmptyTransporteur,
    currentTransporteur,
    isEmptyDestinataire,
    isCreateDestinataire,
    dataDestinataireContact,
    dataDestinataireAdresses,
    gln,
    displayRelay,
    setIsCreateTransporteur,
    setIsCreateDestinataire,
    setIsEmptyTransporteur,
    setIsEmptyDestinataire,
    setSubmitted,
    currentDestinataire,
    isValidDetail,
    cmdResponse,
    setLoading,
    detailData,
    detailDataFromApi,
    attDestinataire,
    attTransporteur,
    cmdFile,
    oldCmdFile,
    fileIdsDelete,
    detailLineDeletedIds,
    isSearchStock,
    searchData,
    onChangeDataDetail,
    onChangeDataFile,
    onChangeDestinataire,
    onChangePhotos,
    onChangeTransporteur,
    onDeleteFile,
    onDeleteLineDetail,
    onCheckValid,
    handleClose,
    handleAttDestinataire,
    activeLot,
    setActiveLot,
    checkLastest,
    setDetailData,
    setForceSearchStock,
    searchStock,
    setSearchData,
    onChangeForcerDluo,
  } = useCommandeEdit()

  //click sauvegarder
  const onFinishForm = async (value: any) => {
    setSubmitted(true)
    if (!isValidDetail) return
    if (cmdResponse?.photos && cmdResponse?.photos > 20) return
    setLoading(true)
    //delete search fields
    delete value.destinataire
    delete value.transporteur
    let detailDataMapping
    // console.log({detailData})
    let detailDataManual = getLineWithSourceFromDataTable(
      detailData,
      sourceType.MANUAL
    )

    removeFakeId(detailDataManual)

    detailDataMapping = convertDataTabletoAPI(detailDataManual, false, true)
    let comArticle = cmdResponse?.com_article || []

    for (let i = 0; i < detailDataMapping.length; i++) {
      detailDataMapping[i].created_by = cmdResponse?.created_by
      detailDataMapping[i].creater_id = cmdResponse?.creater_id
      detailDataMapping[i].modification_by =
        localStorage.getItem(Define.USERNAME) || ''
      detailDataMapping[i].modificationer_id =
        localStorage.getItem(Define.USER_ID) || ''
      detailDataMapping[i].status_code = StatusCommande.CreatedCommande
      detailDataMapping[i].active_lot = activeLot
      detailDataMapping[i].commande_created_at = cmdResponse?.created_at
      detailDataMapping[i].mouvement = comArticle[i]?.mouvement
      detailDataMapping[i].eta = comArticle[i]?.eta
      detailDataMapping[i].type = comArticle[i]?.type

      if (detailDataMapping[i].is_manual_sscc)
        detailDataMapping[i].is_manual_dluo = false
      if (detailDataMapping[i].is_manual_sscc)
        detailDataMapping[i].is_manual_lot = false
    }

    let newCmdFile = getNewFile(cmdFile, oldCmdFile)
    if (newCmdFile)
      for (let i = 0; i < newCmdFile.length; i++) {
        newCmdFile[i].statut = undefined
      }

    const sscc_block: string[] = []
    detailDataMapping.forEach((line) =>
      (line?.sscc_block || []).forEach((sscc) => sscc_block.push(sscc || ''))
    )
    detailDataMapping = detailDataMapping.map((item) => {
      if (item.sscc_block) delete item.sscc_block
      return item
    })
    try {
      await commandeApi.updateCommande({
        entry: {
          ...value,
          id: cmdResponse?.id,
          created_at: cmdResponse?.created_at,
          force_dlou:
            form.getFieldValue('force_dlou') !== undefined
              ? form.getFieldValue('force_dlou')
              : Boolean(cmdResponse?.force_dlou),
          client_code_nom: cmdResponse?.client_code_nom,
          warehouse_code_nom: cmdResponse?.warehouse_code_nom,
          company_code_nom: cmdResponse?.company_code_nom,
          chargement_at: value.chargement_at
            ? value.chargement_at.unix()
            : undefined,
          livraison_at: value.livraison_at
            ? value.livraison_at.unix()
            : undefined,
          destinataire_id: attDestinataire?.destinataire_id,
          des_addresse_id: attDestinataire?.address_id,
          des_contact_id: attDestinataire?.contact_id,
          transporteur_id: attTransporteur?.transporteur_id,
          tran_addresse_id: attTransporteur?.address_id,
          tran_contact_id: attTransporteur?.contact_id,
          imperatif: value.imperatif,
          status_code: StatusCommande.CreatedCommande,
          created_by: cmdResponse?.created_by,
          creater_id: cmdResponse?.creater_id,
          modification_by: localStorage.getItem(Define.USERNAME),
          modificationer_id: localStorage.getItem(Define.USER_ID),
          photos: cmdResponse?.photos,
          com_file: newCmdFile,
          com_livraison: form.getFieldValue('com_livraison'),
          com_preparation: form.getFieldValue('com_preparation'),
          com_article: detailDataMapping,
          active_lot: activeLot,
          scene: CommandeScene.EditScene,
          valid_times: cmdResponse?.valid_times || 0,
          updated_at: cmdResponse?.updated_at,
          mouvement: cmdResponse?.mouvement,
          type: cmdResponse?.type,
          eta: cmdResponse?.eta,
        },
        details_delete: { id: detailLineDeletedIds },
        files_delete: { id: fileIdsDelete },
      })
      setLoading(false)
      notification.open({
        className: 'noti noti-success',
        message: (
          <div className="flex items-center">
            <img src={SUCCESS} alt="success" width={50} className="mr-2.5" />
            Succès: Commande enregistrée
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
        duration: 3,
      })
      // reload current list page
      handleClose()
    } catch (error: any) {
      setLoading(false)
      if (error.response.data.error.includes('Update time is expried')) {
        setIsOpenModalConfirmModifyTable(true)
      } else if (error.response.data.error.includes('record not found')) {
        setIsOpenModalDeleteCommande(true)
      } else
        notification.open({
          className: 'noti noti-error',
          message: (
            <div className="flex items-center">
              <img src={ERROR} alt="error" width={50} className="mr-2.5" />
              Une erreur s'est produite. Merci de réessayer ultérieurement
            </div>
          ),
          placement: 'topRight',
          closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
          duration: 3,
        })
    }
  }

  //click mise en preparation
  const miseEnPreparation = async () => {
    setSubmitted(false)
    setClickOnMEP(true)
    if (!form.getFieldValue('transporteur')) {
      goToEmptyTransporteur.current &&
        goToEmptyTransporteur.current.scrollIntoView()
      setIsEmptyTransporteur(true)
      return
    } else setIsCreateTransporteur(false)
    if (!form.getFieldValue('destinataire')) {
      goToEmptyDestinataire.current &&
        goToEmptyDestinataire.current.scrollIntoView()
      setIsEmptyDestinataire(true)
      return
    } else setIsCreateDestinataire(false)

    if (detailData.length === 0) {
      return
    }

    if (detailData.length === 0 && isValidDetail) return
    if (!isValidDetail) return
    setLoading(true)
    let detailDataManual = getLineWithSourceFromDataTable(
      detailData,
      sourceType.MANUAL
    )

    removeFakeId(detailDataManual)

    let detailDataMapping = convertDataTabletoAPI(detailDataManual, false, true)

    for (let i = 0; i < detailDataMapping.length; i++) {
      const comArticleFromApi = (cmdResponse?.com_article || []).find(
        (item) => item.id === detailDataMapping[i].id
      )
      if (!comArticleFromApi) continue
      detailDataMapping[i].created_by = cmdResponse?.created_by
      detailDataMapping[i].creater_id = cmdResponse?.creater_id
      detailDataMapping[i].modification_by =
        localStorage.getItem(Define.USERNAME) || ''
      detailDataMapping[i].modificationer_id =
        localStorage.getItem(Define.USER_ID) || ''
      detailDataMapping[i].status_code = cmdResponse?.status_code
      detailDataMapping[i].commande_created_at = cmdResponse?.created_at
      detailDataMapping[i].active_lot = true
    }

    const sscc_block: string[] = []
    detailDataMapping.forEach((line) =>
      (line?.sscc_block || []).forEach((sscc) => sscc_block.push(sscc || ''))
    )
    detailDataMapping = detailDataMapping.map((item) => {
      if (item.sscc_block) delete item.sscc_block
      return item
    })
    try {
      const validBody = {
        entry: {
          nom: form.getFieldValue('nom'),
          nom_client: form.getFieldValue('nom_client'),
          priorite: form.getFieldValue('priorite'),
          id: cmdResponse?.id,
          active_lot: true,

          force_dlou:
            form.getFieldValue('force_dlou') !== undefined
              ? form.getFieldValue('force_dlou')
              : Boolean(cmdResponse?.force_dlou),
          client_code_nom: cmdResponse?.client_code_nom,
          warehouse_code_nom: cmdResponse?.warehouse_code_nom,
          company_code_nom: cmdResponse?.company_code_nom,
          chargement_at: form.getFieldValue('chargement_at')
            ? form.getFieldValue('chargement_at').unix()
            : undefined,
          livraison_at: form.getFieldValue('livraison_at')
            ? form.getFieldValue('livraison_at').unix()
            : undefined,
          destinataire_id: attDestinataire?.destinataire_id,
          des_addresse_id: attDestinataire?.address_id,
          des_contact_id: attDestinataire?.contact_id,
          transporteur_id: attTransporteur?.transporteur_id,
          tran_addresse_id: attTransporteur?.address_id,
          tran_contact_id: attTransporteur?.contact_id,
          imperatif: form.getFieldValue('imperatif'),
          relay_detail: form.getFieldValue('relay_detail'),
          status_code: cmdResponse?.status_code,
          created_at: cmdResponse?.created_at,
          created_by: cmdResponse?.created_by,
          creater_id: cmdResponse?.creater_id,
          modification_by: localStorage.getItem(Define.USERNAME) || '',
          modificationer_id: localStorage.getItem(Define.USER_ID) || '',
          photos: cmdResponse?.photos,
          com_file: getNewFile(cmdFile, oldCmdFile),
          com_livraison: form.getFieldValue('com_livraison'),
          com_preparation: form.getFieldValue('com_preparation'),
          com_article: detailDataMapping,
          valid_times: (cmdResponse?.valid_times || 0) + 1,
          updated_at: cmdResponse?.updated_at, //check 409
          sscc_block,
        },
        details_delete: { id: detailLineDeletedIds },
        files_delete: { id: fileIdsDelete },
      }
      await commandeApi.validCommande(validBody)
      let response = await commandeApi.getCommandeById({
        id: cmdResponse?.id || '',
      })
      let newResponse = cloneDeep(response.data.entry)

      let entry = response.data.entry

      if (entry.status_code === StatusCommande.SendOnMissionCommande) {
        await uploadPickingList(cloneDeep(entry), DocumentStatus.Envoye)
      } else await uploadPickingList(cloneDeep(entry), DocumentStatus.Ajour)

      await uploadNonList(cloneDeep(entry), DocumentStatus.DASH)

      //---------------------

      setLoading(false)
      const linkToRedirect = redirectToCmdScreen(
        newResponse,
        cmdResponse,
        pageIndex,
        pageSize
      )
      navigate(linkToRedirect)
    } catch (e: any) {
      setLoading(false)
      if (e.response.data.error.includes('record not found')) {
        setIsOpenModalDeleteCommande(true)
      } else if (e.response.data.error.includes('Update time is expried')) {
        setIsOpenModalConfirmModifyTable(true)
      } else {
        notification.open({
          className: 'noti noti-error',
          message: (
            <div className="flex items-center">
              <img src={ERROR} alt="error" width={50} className="mr-2.5" />
              Une erreur s'est produite. Merci de réessayer ultérieurement
            </div>
          ),
          placement: 'topRight',
          closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
          duration: 3,
        })
      }
    }
  }

  const onCloseModalConfirmModify = async () => {
    if (isOpenModalConfirmModifyTable) {
      setIsOpenModalConfirmModifyTable(false)
    }
    setLoading(true)
    try {
      let response = await commandeApi.getCommandeById({
        id: cmdResponse?.id || '',
      })
      let newResponse = cloneDeep(response.data.entry)

      let linkToRedirect = redirectToCmdScreen(
        newResponse,
        cmdResponse,
        pageIndex,
        pageSize
      )

      if (!linkToRedirect) {
        linkToRedirect += `${pathname}?count=${count + 1}`
      } else linkToRedirect += `&count=${count + 1}`
      navigate(linkToRedirect, { replace: true })
      dispatch(clearCurrentDestinataire())
      dispatch(clearCurrentTransporteur())
    } catch (e: any) {
      handleClose()
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const link = redirectToCmdScreen(
      cmdResponse,
      cmdResponse,
      pageIndex,
      pageSize
    )
    if (link === '') return
    if (link.includes('commande-edit')) {
      return
    } else {
      navigate(link, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmdResponse])

  const handleActiveCommande = async (isActive: boolean) => {
    setActiveLot(isActive)
  }

  const handleOnclickSupprimer = async () => {
    setOpenPopupDelete(true)
  }

  //click supprimer to delete commande
  const onConfirmDelete = async (id: string) => {
    setLoading(true)
    setOpenPopupDelete(false)
    try {
      const status = await checkLastest({
        id: params.id || '',
        updated_at: cmdResponse?.updated_at || 0,
      })
      if (status) {
        await commandeApi.deleteCommandeById({
          id,
          updated_at: cmdResponse?.updated_at || 0,
        })
        handleClose()
      } else {
        setIsOpenModalConfirmModifyTable(true)
      }
    } catch (e: any) {
      if (e.response.data.error.includes('Update time is expried')) {
        setIsOpenModalConfirmModifyTable(true)
      } else if (e.response.data.error.includes('record not found')) {
        setIsOpenModalDeleteCommande(true)
      } else
        notification.open({
          className: 'noti noti-error',
          message: (
            <div className="flex items-center">
              <img src={ERROR} alt="error" width={50} className="mr-2.5" />
              Une erreur s'est produite. Merci de réessayer ultérieurement
            </div>
          ),
          placement: 'topRight',
          closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
          duration: 3,
        })
    } finally {
      setLoading(false)
    }
  }

  const onCloseModalDeleteCommande = () => {
    handleClose()
  }

  const onSearchStock = async () => {
    setClickedOnSearch(true)
    if (detailData.length === 0) {
      return
    }

    if (detailData.length === 0 && isValidDetail) return
    if (!isValidDetail) return
    const forceDluo =
      form.getFieldValue('force_dlou') !== undefined
        ? form.getFieldValue('force_dlou')
        : Boolean(cmdResponse?.force_dlou)
    await searchStock(forceDluo)
    setClickedOnSearch(false)
  }

  useEffect(() => {
    focusById(commandeKey['n-client'])
  }, [])

  return (
    <Form id={'commande-edit'} onFinish={onFinishForm} form={form}>
      <Modal
        prefixCls="modal-new-stock"
        centered
        open={true}
        onCancel={handleClose}
        width={'90%'}
        className="border-solid border-secondary rounded-xl"
        footer={null}
        destroyOnClose={true}
      >
        {(loading || initLoading) && <Loading />}
        <div
          className="flex items-baseline border-none pb-2 mb-2"
          style={{ borderBottom: '1px solid #B7B7B7' }}
        >
          <div className="flex items-center">
            <span className="modal-title-heading-1 mr-1">Commande</span>
            <span className="text-[#20458F] text-2xl mr-3">
              (n° {cmdResponse?.nom})
            </span>
            <span>
              <CSwitch
                id={commandeKey['active']}
                nextId={commandeKey['n-input']}
                onChange={(isActive) => {
                  handleActiveCommande(isActive)
                }}
                checked={activeLot}
                defaultChecked={true}
                disabled={loading}
              />
            </span>
            <span className="font-normal text-lg text-[#20458F]">Active</span>
          </div>
        </div>
        <CustomPopup
          title={'Commande supprimée'}
          type={popupType.CONFIRM}
          isOpen={isOpenModalDeleteCommande}
          onConfirm={onCloseModalDeleteCommande}
        >
          <p className="text-xl">
            La commande a été supprimée par un autre utilisateur.
          </p>
        </CustomPopup>
        <CustomPopup
          title={'Confirmation de mise en préparation'}
          type={popupType.VALIDE}
          isOpen={isOpenModalConfirmModifyTable}
          onConfirm={onCloseModalConfirmModify}
        >
          <p className="text-xl">
            La commande doit-être mise à jour pour prendre en compte
            <br /> les derniers renseignements saisis.
          </p>
        </CustomPopup>

        <CustomPopup
          title="Confirmation de la suppression?"
          type={popupType.DELETE}
          isOpen={openPopupDelete}
          onConfirm={async () => onConfirmDelete(params.id || '')}
          onClose={() => {
            setOpenPopupDelete(false)
            focusById(commandeKey['supprimer'])
          }}
        >
          <p className="text-lg text-[#505050] my-10">
            Êtes-vous sûr de vouloir supprimer cette commande?
          </p>
        </CustomPopup>
        <div className="flex justify-between italic text-[#808080]">
          <div className="text-[#848484]">
            {`Création : ${moment((cmdResponse?.created_at || 0) * 1000).format(
              t('time-format')
            )}`}
          </div>
          <div className="text-[#848484]">
            {`Modification : ${moment(
              (cmdResponse?.updated_at || 0) * 1000
            ).format(t('time-format'))}`}
          </div>
        </div>
        <div className="h-2"></div>

        <div className="w-full overflow-y-auto main-content-2">
          <SectionLayout title="Informations">
            <div className="flex infomation-section justify-between">
              <Form.Item
                label="N°"
                name="nom"
                rules={[{ required: true, message: '' }]}
                colon={false}
              >
                <Input
                  data-previous-id={commandeKey['active']}
                  maxLength={20}
                  disabled
                />
              </Form.Item>

              <Form.Item
                label="N° client"
                name="nom_client"
                colon={false}
                className="item-client-code"
                validateStatus={errorClientCode ? 'error' : undefined}
                help={errorClientCode}
              >
                <Input
                  maxLength={20}
                  id={commandeKey['n-client']}
                  data-next-id={commandeKey['priorite']}
                  onChange={(e) => {
                    form.setFieldValue(
                      'nom_client',
                      removeTones(e.target.value)
                    )

                    setErrorClientCode('')
                  }}
                />
              </Form.Item>

              <Form.Item label="Client" colon={false}>
                <Input
                  disabled
                  style={{ background: '#F2F2F2' }}
                  value={cmdResponse?.client_code_nom}
                />
              </Form.Item>
              <Form.Item
                label="Priorité"
                name="priorite"
                colon={false}
                className="priorite-item"
              >
                <Select
                  id={commandeKey['priorite']}
                  aria-roledescription={commandeKey['Forcer-la-DLUO']}
                  aria-keyshortcuts={commandeKey['n-client']}
                  style={{ width: 87 }}
                  className="responsive-input"
                >
                  {[5, 4, 3, 2, 1].map((i) => (
                    <Select.Option key={i} value={i}>
                      {i}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </SectionLayout>
          {!initLoading ? (
            <DetailSection
              onChangeForcerDluo={onChangeForcerDluo}
              isSearch={isSearchStock}
              mode={MODE.EDIT}
              onDeleteLine={onDeleteLineDetail}
              onChangePhotos={onChangePhotos}
              initData={detailDataFromApi}
              dataFromSearch={searchData}
              onChangeData={(value) => {
                onChangeDataDetail(value, CommandeScene.EditScene)
                // setSearchData([]);
              }}
              alert={createAlert(
                submitted,
                clickedOnSearch,
                clickedOnMEP,
                isValidDetail,
                detailData
              )}
              onCheckValid={onCheckValid}
              valuePhoto={cmdResponse?.photos}
              cmdResponse={cmdResponse}
              onSearchStock={onSearchStock}
            />
          ) : null}
          <SectionLayout
            title="Documents"
            action={<Action onClickPlusBtn={() => setOpenPopupImport(true)} />}
          >
            <DisplayDocumentsCmd
              initData={cmdFile}
              type={ModalName.COMMANDE}
              openImport={openPopupImport}
              closeImport={() => setOpenPopupImport(false)}
              onChangeData={onChangeDataFile}
              onDeleteFile={onDeleteFile}
              onEnoyerEnMission={() => {}}
              cancelledAt={cmdResponse?.canceled_at}
            />
          </SectionLayout>
          <FormLivraison
            isCreateTransporteur={isCreateTransporteur}
            setIsCreateTransporteur={setIsCreateTransporteur}
            goToEmptyTransporteur={goToEmptyTransporteur}
            isEmptyTransporteur={isEmptyTransporteur}
            onChangeTransporteur={onChangeTransporteur}
            gln={gln}
            form={form}
            detailLength={detailData.length}
          />
          <FormDestination
            isCreateDestinataire={isCreateDestinataire}
            setIsCreateDestinataire={setIsCreateDestinataire}
            isEmptyDestinataire={isEmptyDestinataire}
            onChangeDestinataire={onChangeDestinataire}
            currentTransporteur={currentTransporteur}
            form={form}
            goToEmptyDestinataire={goToEmptyDestinataire}
            currentDestinataire={currentDestinataire}
            dataDestinataireAdresses={dataDestinataireAdresses}
            dataDestinataireContact={dataDestinataireContact}
            handleAttDestinataire={handleAttDestinataire}
            displayRelay={displayRelay}
          />
          <SectionLayout title="Commentaire">
            <div className="flex">
              <Form.Item
                name={['com_preparation', 'comment']}
                label="Préparation"
                colon={false}
              >
                <TextArea
                  style={{
                    height: 100,
                    width: 390,
                    resize: 'none',
                    marginLeft: '7%',
                  }}
                  showCount
                  maxLength={200}
                  id={commandeKey['preparation-commentaire']}
                  data-previous-id={commandeKey['code-nom-destinataire']}
                  data-next-id={commandeKey['livraison-commentaire']}
                />
              </Form.Item>
              <Form.Item
                name={['com_livraison', 'comment']}
                label="Livraison"
                colon={false}
                className="ml-10"
              >
                <TextArea
                  style={{
                    height: 100,
                    width: 390,
                    resize: 'none',
                    marginLeft: '7%',
                  }}
                  showCount
                  maxLength={200}
                  id={commandeKey['livraison-commentaire']}
                  data-previous-id={commandeKey['preparation-commentaire']}
                  data-next-id={commandeKey['supprimer']}
                />
              </Form.Item>
            </div>
          </SectionLayout>
        </div>

        <div className="flex justify-between items-center footer-content">
          <div>
            <button
              id={commandeKey['supprimer']}
              disabled={loading}
              onClick={() => handleOnclickSupprimer()}
              className={clsx(
                'supprimer border-red border-solid bg-white hover:text-red text-red text-lg font-semibold pl-12 pr-5 py-1 rounded-lg mr-4 relative',
                !loading ? 'cursor-pointer' : 'cursor-not-allowed'
              )}
            >
              Supprimer commande
            </button>
            <button
              disabled={
                loading ||
                !activeLot ||
                currentDestinataire.status === StatusAPI.calling ||
                currentTransporteur.status === StatusAPI.calling
              }
              className={clsx(
                '  border-solid bg-white  text-lg font-semibold  pl-12 pr-5 py-1 rounded-lg relative',
                !loading || activeLot ? 'cursor-pointer' : 'cursor-not-allowed',
                activeLot
                  ? 'envoyer border-[#01E37A] text-[#01E37A] hover:text-[#01E37A]'
                  : 'envoyer-disabled border-[#ccc] text-[#ccc] hover:text-[#ccc]'
              )}
              onClick={miseEnPreparation}
            >
              Mettre en préparation
            </button>
          </div>

          <Button
            data-next-id={commandeKey['n-client']}
            disabled={
              loading ||
              currentDestinataire.status === StatusAPI.calling ||
              currentTransporteur.status === StatusAPI.calling
            }
            prefixCls=" text-btn-submit-last-modal"
            className={clsx(
              'btn-submit-modal text-btn-submit-last-modal hover:text-white',
              !loading ? 'cursor-pointer' : 'cursor-not-allowed'
            )}
            key="submit"
            htmlType="submit"
            form={'commande-edit'}
          >
            Sauvegarder
          </Button>
        </div>
      </Modal>
    </Form>
  )
}

export default CommandeEdit

const alertNode = (text: string): ReactNode => (
  <span
    style={{ fontSize: 12, color: 'red', marginLeft: 5 }}
  >{`(${text})`}</span>
)

function createAlert(
  submitted: boolean,
  clickedOnSearch: boolean,
  clickedOnMEP: boolean,
  isValidDetail: boolean,
  detailData: DataTableParam[][]
): ReactNode {
  if (submitted && !isValidDetail)
    return alertNode('Veuillez valider toutes ces lignes suivantes')

  if ((clickedOnMEP || clickedOnSearch) && !isValidDetail)
    return alertNode('Veuillez valider toutes ces lignes suivantes')

  if ((clickedOnMEP || clickedOnSearch) && detailData.length === 0)
    return alertNode('Veuillez ajouter une référence')
  return <></>
}
